var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-large",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "jtl-entity-dialog-component" },
        [
          _c("jtl-form", {
            ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
            on: { change: _vm.formOnChange },
          }),
          _c(
            "div",
            {
              staticClass: "ant-row ant-form-item",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "ant-col ant-col-5 ant-form-item-label" },
                [
                  _c("label", { attrs: { title: "超时时长" } }, [
                    _vm._v("超时时长"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ant-col ant-col-12 ant-form-item-control-wrapper",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "margin-top": "6px",
                  },
                },
                [
                  _c("a-input-number", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入超时时长" },
                    model: {
                      value: _vm.model.timeoutDuration,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "timeoutDuration", $$v)
                      },
                      expression: "model.timeoutDuration",
                    },
                  }),
                  _c("a-radio-group", {
                    attrs: { options: _vm.plainOptions },
                    model: {
                      value: _vm.model.timeoutDurationType,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "timeoutDurationType", $$v)
                      },
                      expression: "model.timeoutDurationType",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "ant-row ant-form-item",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "ant-col ant-col-5 ant-form-item-label" },
                [
                  _c("label", { attrs: { title: "自动关闭时长" } }, [
                    _vm._v("自动关闭时长"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ant-col ant-col-12 ant-form-item-control-wrapper",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "margin-top": "6px",
                  },
                },
                [
                  _c("a-input-number", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入自动关闭时长" },
                    model: {
                      value: _vm.model.terminationDuration,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "terminationDuration", $$v)
                      },
                      expression: "model.terminationDuration",
                    },
                  }),
                  _c("a-radio-group", {
                    attrs: { options: _vm.plainOptions },
                    model: {
                      value: _vm.model.terminationDurationType,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "terminationDurationType", $$v)
                      },
                      expression: "model.terminationDurationType",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "ant-row ant-form-item",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "ant-col ant-col-5 ant-form-item-label" },
                [_c("label", { attrs: { title: "描述" } }, [_vm._v("描述")])]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ant-col ant-col-12 ant-form-item-control-wrapper",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "margin-top": "6px",
                  },
                },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "请输入描述", rows: 3 },
                    model: {
                      value: _vm.model.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "description", $$v)
                      },
                      expression: "model.description",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _vm.viewMode !== _vm.ViewModeType.VIEW
            ? _c(
                "jtl-button",
                {
                  attrs: {
                    type: "primary",
                    "click-prop": _vm.dialogOK.bind(this),
                  },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }