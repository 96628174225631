




















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { WorkOrderConfigEntityModel, WorkOrderConfigQueryModel } from '@common-src/entity-model/work-order-config-entity';
import WorkOrderConfigListService from '@common-src/service/work-order-config-list';
import WorkOrderConfigDialog from './work-order-config-dialog.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'work-order-config-dialog': WorkOrderConfigDialog
    }
})
export default class WorkOrderConfigListComponent extends TableDialogFormComponent<WorkOrderConfigEntityModel, WorkOrderConfigQueryModel> {
    WorkOrderConfigEntityModel = WorkOrderConfigEntityModel;

    created() {
        this.initTable({
            service: WorkOrderConfigListService,
            queryModel: new WorkOrderConfigQueryModel(),
            tableColumns: WorkOrderConfigEntityModel.getTableColumns()
        });

        this.getList();
    }

    configClick(record) {
        this.$router.push(`/dashboard/work-order-config/${record.id}`);
    }

    deployClick(record) {
        (this.service as any).deploy(record.id).then(res => {
            this.showMessageSuccess('更新成功');

            this.getList();
        });
    }

    addHandlerClick() {
        const model = new WorkOrderConfigEntityModel();
        (this.$refs.workOrderConfigDialog as WorkOrderConfigDialog).dialogOpen(model, null, ViewModeType.NEW, false);
    }

    editHandlerClick(record: any) {
        WorkOrderConfigListService.retrieve(record.id).then(res => {
            (this.$refs.workOrderConfigDialog as WorkOrderConfigDialog).dialogOpen(record, null, ViewModeType.UPDATE, false);
        }).catch((err) => {
            console.log(err);
        });
    }
}
