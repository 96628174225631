












































import { Component, Ref } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { FormControlOptionModel, FormControlType } from '@common-src/model/form-control';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';
import { WorkOrderConfigEntityModel } from '@common-src/entity-model/work-order-config-entity';
import WorkOrderConfigListService from '@common-src/service/work-order-config-list';

@Component
export default class WorkOrderConfigDialog extends FormDialogComponent<WorkOrderConfigEntityModel> {
    plainOptions = [
        { label: '分', value: 'MINUTE' },
        { label: '时', value: 'HOUR' },
        { label: '天', value: 'DAY' }

    ];
    model: WorkOrderConfigEntityModel = new WorkOrderConfigEntityModel();

    formOnChange(formControlModel: FormControlOptionModel) {
        this.$emit('change', formControlModel);
    }
    dialogOpen(model: WorkOrderConfigEntityModel, service?: ICRUDQ<any, any>, viewMode?: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.model = model;
        this.initDialogTitle(this.viewMode);
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(this.model, this.viewMode);
            }
        });
    }

    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            this.jtlForm.formModel.description = this.model.description;
            this.jtlForm.formModel.timeoutDuration = this.model.timeoutDuration;
            this.jtlForm.formModel.timeoutDurationType = this.model.timeoutDurationType;
            this.jtlForm.formModel.terminationDuration = this.model.terminationDuration;
            this.jtlForm.formModel.terminationDurationType = this.model.terminationDurationType;

            if (this.viewMode === ViewModeType.NEW) {
                WorkOrderConfigListService.create(this.jtlForm.formModel).then((ret) => {
                    this.showMessageSuccess('添加成功');
                    this.dialogClose();
                    this.$emit('dialogOK');
                }).catch(err => {
                    throw err;
                });
            }
            if (this.viewMode === ViewModeType.UPDATE) {
                WorkOrderConfigListService.update(this.jtlForm.formModel).then((ret) => {
                    this.dialogClose();
                    this.showMessageSuccess('保存成功');
                    this.$emit('dialogOK');
                }).catch(err => {
                    throw err;
                });
            }
        }).catch(err => {
            throw err;
        });
    }
}
